<template>
  <div>
    <metainfo>
      <template #title="{ content }">
        {{ '다이노스밴드 | ' + content }}
      </template>
    </metainfo>
    <div id="modal-target"></div>
    <router-view />
  </div>
</template>