import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createMetaManager, defaultConfig } from 'vue-meta'


import '@/theme/index.scss'

createApp(App)
  .use(router)
  .use(createMetaManager(false, {
    ...defaultConfig,
    keywords: {
      tag: 'meta',
    },
    author: {
      tag: 'meta',
    },
  }))
  .mount('#app')
