import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'Home',
    path: '/',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
  },
  {
    name: 'Recruit',
    path: '/recruit',
    component: () => import(/* webpackChunkName: "Recruit" */ '../views/Recruit.vue'),
  },
  {
    name: 'Contact',
    path: '/contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue'),
  },
  {
    name: 'Policy',
    path: '/policy',
    component: () => import(/* webpackChunkName: "Policy" */ '../views/PrivacyPolicy.vue'),
  },
  {
    name: 'BeezValleyApps',
    path: '/beezvalleyapps',
    component: () => import(/* webpackChunkName: "Policy" */ '../views/BeezValleyApps.vue'),
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'not-found', 
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if ((from.fullPath === '/' && to.fullPath === '/') || (from.fullPath === to.fullPath)) {
      return { top: 0, left: 0, behavior: 'smooth' }
    } else {
      return { top: 0, left: 0 }
    }
  },
})

export default router
